.container {
  display: flex;
  flex-direction: column;
  position: relative;

  > img {
    position: absolute;
    margin: 40px 0 15px 12px;
  }

  .labelContainer {
    display: flex;
    gap: 3px;
    align-items: center;
    justify-content: left;
    margin-bottom: 0.5rem;
  }
}

.dropdown {
  position: relative;

  &__button,
  &__option {
    font-family: var(--lincd-font-family-primary);
    font-size: var(--lincd-font-size-regular);
    font-weight: 400;
    line-height: 1.5;
    border-radius: 30px;
    background-color: var(--lincd-color-secondary);
    color: var(--lincd-color-primary);
    // border: var(--lincd-primary-button-border);
    box-shadow: none !important;
    //min-width: 100%;
    //max-width:90vw;
    width: min(calc(600px - 48px), calc(100vw - 48px));
    margin: 0 !important;
    padding: 12px 0;
    text-align: left;
    text-indent: 1rem;
    height: 51px;

    /* to remove the up down arrows & calendar default on the input with type number OPENING*/
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button,
    input[type='date']::-webkit-inner-spin-button,
      // input[type='date']::-webkit-calendar-picker-indicator {
      //   display: none;
      //   -webkit-appearance: none;
      // }
    input[type='date']::-webkit-calendar-picker-indicator {
      background: transparent;
      bottom: 0;
      color: transparent;
      cursor: pointer;
      height: auto;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: auto;
    }

    /* Firefox */
    input[type='number'],
    input[type='date'] {
      -moz-appearance: textfield;
    }

    /* to remove the up down arrows on the input with type number CLOSING*/
  }

  &__button {
    border: var(--lincd-primary-button-border);
    appearance: none; // remove arrow on select

    // &::before {
    //   content: '';
    //   position: absolute;
    //   right: 20px !important;
    //   top: 50px;
    //   z-index: 10000;
    //   width: 8px;
    //   height: 8px;
    //   border: 2px solid #333;
    //   border-top: 2px solid transparent;
    //   border-right: 2px solid transparent;
    //   transform: rotate(-45deg);
    //   transition: 0.5s;
    //   pointer-events: none;
    // }
  }

  &__option {
    list-style: none;
    border-radius: 0;
    text-indent: 0;

    &:first-child {
      border-radius: 15px 15px 0 0 !important;
      border-top: var(--lincd-primary-button-border);
      border-right: var(--lincd-primary-button-border);
      border-left: var(--lincd-primary-button-border);
    }

    &:last-child {
      border-radius: 0 0 15px 15px !important;
    }

    &:not(:first-child) {
      border: var(--lincd-primary-button-border);
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.listbox {
  font-size: var(--lincd-font-size-regular);
  box-sizing: border-box;
  padding: 6px 0;
  margin-top: -3px;
  overflow: auto;
  //width: 550px !important;
}

.popper {
  z-index: 1;
}

.placeholder {
  color: var(--lincd-input-placeholder-color);
}

.selectField {
  position: relative;

  &::after {
    content: '';
    display: inline-block;
    background-image: url('/images/chevron-down.png');
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 25px;
    height: 25px;
    z-index: 51;
    background-size: 25px;
    background-repeat: no-repeat;
  }
}
