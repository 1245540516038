.container {
  &__form {
    min-width: 18rem;
    margin-top: 1.5rem;

    // :not(:last-child) {
    //   margin-bottom: 12px;
    // }
  }
}

.form {
  &__inputContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;

    // get element of input date
    > div {
      // fixed date input on ios
      &:nth-child(3) {
        > div {
          display: flex;
        }
      }
    }

    & > img {
      position: absolute;
      left: 3px;
    }

    label {
      color: var(--lincd-primary-black-500);
      text-align: left;
      width: 100%;
    }
  }
}

.input {
  border: 1px solid #0063ec !important;
  background: #e8f1ff !important;
  border-radius: 25px !important;
  text-indent: 1rem;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    color: var(--lincd-color-primary);
  }

  &[type='date'] {
    // text-indent: 18px;
    flex: 1;
    height: 51px;

    // remove arrows on android
    appearance: none;
    -webkit-appearance: none;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button,
    &::-webkit-calendar-picker-indicator {
      display: none;
      appearance: none;
      -webkit-appearance: none;
    }

    // date field text left on ios
    &::-webkit-date-and-time-value {
      text-align: left;
    }
  }

  // only for web
  &.dateInput {
    text-indent: 0.5rem;
  }

  &.textarea {
    text-indent: 1rem;
  }

  // provider code error border color
  &.errorText {
    border-color: var(--ld-ref-palette-error50) !important;

    + p {
      color: var(--ld-ref-palette-error50);
    }
  }

  &.disabledInput {
    cursor: not-allowed;
    border: var(--lincd-input-border) !important;

    input {
      background-color: var(--lincd-input-background-color);
    }
  }
}

.input,
.selectOption {
  // color: #000;
  width: 100% !important;
  color: var(--lincd-color-primary);
  min-height: 51px;
  font-size: 1rem !important;
}

.selectOption {
  min-width: 100% !important;
  // text-indent: 38px;
}

.primaryButton {
  max-width: 100%;
  margin: 30px 0 50px !important;
}

.uploaderButton {
  margin: 0 !important;
  gap: 1rem;
  background-color: #fff !important;
  border-radius: 0.9rem !important;
  color: #000;
}

.uploader {
  display: flex;
  align-items: center;
  gap: 20px;

  &__photo {
    position: relative;
  }

  &__checkmark {
    position: absolute;
    right: 10px;
    bottom: 7px;
    width: 26px;
    height: auto;
  }

  &__profile {
    border-radius: 50%;
    padding: 8px;
    border: 3px solid var(--lincd-button-bg-primary);
  }

  &__button {
    background-color: transparent !important;
    color: #000;
    // max-width: 35%;
    border-radius: 15px !important;
  }
}

.loadingInput {
  top: 33px;
}

.noCodeCheckbox {
  margin-top: 30px;
}

// react-date-picker
.datepicker {
  width: 100%;

  // react-date-picker__wrapper
  > div {
    opacity: 1;
    height: 51px;
    border: 0;

    // react-date-picker__inputGroup
    > div {
      width: 100%;
      text-align: left;
    }

    // react-date-picker__inputGroup__divider
    // prettier-ignore
    span[class="react-date-picker__inputGroup__divider"] {
      padding-left: 3px;
      padding-right: 3px;
      text-indent: 0;
    }

    // prettier-ignore
    span[class="react-date-picker__inputGroup__leadingZero"] {
      text-indent: 0;
    }

    // button clear
    > button {
      width: auto !important;
      height: auto !important;
      border-radius: 0 !important;
      box-shadow: none !important;
      margin: 0 5px 0 0 !important;
    }
  }
}

.calendar {
  display: block;

  button {
    width: auto !important;
    height: auto !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    margin: 0 !important;
  }

  > div {
    // react-calendar__navigation
    &:first-of-type {
      button {
        font-size: 1rem;

        // react-calendar__navigation__label
        &:nth-child(3) {
          font-weight: bold;
          flex: 0 0 150px;
        }
      }
    }
  }
}

.labelContainer {
  display: flex;
  gap: 3px;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  display: block;
}
