@import 'scss/theme.scss';
@import 'scss/component-overwrites.scss';
@import 'scss/normalize.scss';
@import 'scss/basic.scss';
@import 'scss/_helper';

html,
body {
  font-family: var(--lincd-font-family-primary);
  color: var(--lincd-color-text-black);
}

a {
  color: var(--ld-app-color-primary);
  text-decoration: none;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .App {
    // display: block;
    // Use Display Flex so It wil consistent through all pages
    display: block;
    flex-direction: column;
    width: var(--lincd-container-min-width);
    margin: 0 auto;
  }
  .Container {
    margin: 0 auto;
    width: var(--lincd-container-min-width);
    height: 100%;
  }
  p {
    font-family: var(--lincd-font-family-primary);
    font-size: pxToRem(16px);
  }
  h1 {
    font-family: var(--lincd-font-family-primary);
    font-size: pxToRem(24px);
    color: var(--lincd-color-text-black);
  }
  h2 {
    font-family: var(--lincd-font-family-primary);
    font-size: pxToRem(22px);
    color: var(--lincd-color-text-black);
  }
  h3 {
    font-family: var(--lincd-font-family-primary);
    font-size: pxToRem(20px);
    color: var(--lincd-color-text-black);
  }
  h4 {
    font-family: var(--lincd-font-family-primary);
    font-size: pxToRem(18px);
    color: var(--lincd-color-text-black);
  }
  h5 {
    font-family: var(--lincd-font-family-primary);
    font-size: pxToRem(18px);
    color: var(--lincd-color-text-black);
  }
  li {
    font-size: pxToRem(16px);
    margin-bottom: 10px;
    font-family: var(--lincd-font-family-primary);
  }
  .spinnerPosition {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@media only screen and (min-width: 769px) {
  h1 {
    font-family: var(--lincd-font-family-primary);
    font-size: pxToRem(26px);
    color: var(--lincd-color-text-black);
  }

  h2 {
    font-family: var(--lincd-font-family-primary);
    font-size: pxToRem(24px);
    color: var(--lincd-color-text-black);
  }
  h3 {
    font-family: var(--lincd-font-family-primary);
    font-size: pxToRem(22px);
    color: var(--lincd-color-text-black);
  }
  h4 {
    font-family: var(--lincd-font-family-primary);
    font-size: pxToRem(20px);
    color: var(--lincd-color-text-black);
  }
  h5 {
    font-family: var(--lincd-font-family-primary);
    font-size: pxToRem(18px);
    color: var(--lincd-color-text-black);
  }
  p {
    font-size: pxToRem(16px);
  }
  li {
    font-size: pxToRem(16px);
    margin-bottom: 10px;
    font-family: var(--lincd-font-family-primary);
  }
}

button {
  flex: 1;
  width: 100% !important;
  height: 55px !important;
  margin: 15px !important;
  border-radius: 30px !important;
  box-shadow: var(--lincd-color-box-shadow) !important;
}

.buttonPrimary {
  background-image: linear-gradient(
                  var(--lincd-gradient-primary-brand),
                  var(--lincd-gradient-secondary-brand)
  );
  width: 100%;
  border: 1px solid var(--lincd-button-border-color);
  height: pxToRem(60px);
  color: var(--lincd-color-text-white);
  margin-right: 10px;
  border-radius: pxToRem(10px);
  box-shadow: var(--lincd-color-box-shadow);

  &:hover {
    background: var(--lincd-button-hover-bg);
  }

  &.active {
    box-shadow: var(--lincd-color-box-shadow-inset);
  }
}

img {
  max-width: 100%;
}
