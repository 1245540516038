.main {
  // height: calc(100vh - 11vh);
  // overflow-y: scroll;
  // overflow-x: hidden;
  display: block;
}

.headerSafeArea {
  margin-top: var(--safe-area-top);
}

.moreSpaceForFooter {
  width: 100%;
  height: calc(var(--footer-height) + 30px);
}
