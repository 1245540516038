.buttonRoot {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  line-height: 1.5;
  color: white;
  border: 1px solid #0063ec;
  background-color: #0063ec;
  border-radius: 30px;
  font-weight: 400;
  padding: 8px 16px;
  cursor: pointer;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease;
  gap: 20px;
  transform: translateY(0);
  margin-top: 8px;

  &:hover {
    cursor: pointer;
    filter: brightness(110%);
  }

  &:focus {
    cursor: pointer;
    transform: translateY(5px);
    transition: transform 0.2s ease;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  // &.contained {
  //   border: solid 1px $grayColor;
  //   background-color: $grayColor;
  // }

  &.outlined {
    // border: solid 1px $grayColor;
    // background-color: #fff;

    &.primary {
      background-color: #0063ec;
    }
  }

  &.text {
    border: none;
  }
}
