@import '../scss/basic.scss';
@import '../scss/_helper.scss';

.buttonContainer {
  background-image: linear-gradient(
                  var(--lincd-gradient-primary-brand),
                  var(--lincd-gradient-secondary-brand)
  );
  width: 100%;
  border: 1px solid var(--lincd-button-border-color);
  height: pxToRem(60px);
  color: var(--lincd-color-text-white);
  margin-right: 10px;
  border-radius: pxToRem(10px);
  box-shadow: var(--lincd-color-box-shadow);

  &:hover {
    background: var(--lincd-button-hover-bg);
  }

  &.active {
    box-shadow: var(--lincd-color-box-shadow-inset);
  }
}

.buttonInner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.IconTitle {
  width: auto;
  font-family: var(--lincd-font-family-primary);
  font-weight: var(--lincd-font-weight-bold);
  margin-left: pxToRem(10px);
}

.IconImg {
  width: pxToRem(30px);
  height: pxToRem(30px);
}
