.layout {
  padding-bottom: 2rem;
}

.container {
  max-width: 600px;
  margin: 0 auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.headlineWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headline {
  font-size: 20px;
  color: var(--lincd-primary-black-400-grey);
  margin-top: 0;
  margin-bottom: 5px;

  & + p {
    margin-top: 0;
  }
}

.searchInput {
  input {
    padding: 7px 15px;
  }
}

.providersList {
  width: 100%;

  .providerItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #ddd;

    &:nth-child(even) {
      background-color: #f2f2f2;
    }

    &.header {
      font-weight: 500;
      letter-spacing: 1px;
    }
  }

  .providerName {
    width: 50%;
    color: var(--lincd-primary-gray-100);
    font-size: 14px;
  }

  .providerActions {
    display: flex;
    justify-content: flex-end;
    width: 200px;

    button {
      margin-right: 5px;
    }
  }
}

.btnGroup {
  display: flex;
  gap: 10px;
}

.btn {
  height: auto !important;
  width: auto !important;
  margin: 0 !important;
  padding: 3px 12px;
}

.loading,
.scrollTop {
  text-align: center;
  padding: 1rem;
}

.modalBody {
  max-width: 550px;
}

.modalContent {
  background-color: #fff;
}

.scrollTop {
  cursor: pointer;
}
