:root {
  //@TODO: should border color be darker variant of primary? new app variable?
  --ld-button-filled-primary-font-color: var(--ld-app-color-on-primary);
  --ld-button-filled-primary-border-color: var(--ld-app-color-primary);
  --ld-button-filled-primary-background-color: var(--ld-app-color-primary);
  --ld-button-filled-primary-hover-background-color: var(
    --ld-ref-palette-primary50
  );

  --ld-button-filled-secondary-font-color: var(--ld-app-color-on-secondary);
  --ld-button-filled-secondary-border-color: var(--ld-app-color-secondary);
  --ld-button-filled-secondary-background-color: var(--ld-app-color-secondary);
  --ld-button-filled-secondary-hover-background-color: var(
    --ld-ref-palette-secondary50
  );

  --ld-button-filled-tertiary-font-color: var(--ld-app-color-on-tertiary);
  --ld-button-filled-tertiary-border-color: var(--ld-app-color-tertiary);
  --ld-button-filled-tertiary-background-color: var(--ld-app-color-tertiary);
  --ld-button-filled-tertiary-hover-background-color: var(
    --ld-ref-palette-tertiary50
  );

  --ld-button-outlined-primary-border-color: var(--ld-app-color-primary);
  --ld-button-outlined-primary-background-color: var(--ld-app-color-on-primary);
  --ld-button-outlined-primary-font-color: var(--ld-app-color-primary);
  --ld-button-outlined-primary-hover-background-color: var(
    --ld-ref-palette-primary98
  );

  --ld-button-outlined-secondary-border-color: var(--ld-app-color-secondary);
  --ld-button-outlined-secondary-background-color: var(
    --ld-app-color-on-secondary
  );
  --ld-button-outlined-secondary-font-color: var(--ld-app-color-secondary);
  --ld-button-outlined-secondary-hover-background-color: var(
    --ld-ref-palette-secondary98
  );

  --ld-button-outlined-tertiary-border-color: var(--ld-app-color-tertiary);
  --ld-button-outlined-tertiary-background-color: var(
    --ld-app-color-on-tertiary
  );
  --ld-button-outlined-tertiary-font-color: var(--ld-app-color-tertiary);
  --ld-button-outlined-tertiary-hover-background-color: var(
    --ld-ref-palette-tertiary98
  );

  --ld-button-text-primary-font-color: var(--ld-app-color-primary);
  --ld-button-text-primary-hover-background-color: var(
    --ld-ref-palette-primary98
  );
  --ld-button-text-secondary-font-color: var(--ld-app-color-secondary);
  --ld-button-text-secondary-hover-background-color: var(
    --ld-ref-palette-secondary98
  );
  --ld-button-text-tertiary-font-color: var(--ld-app-color-tertiary);
  --ld-button-text-tertiary-hover-background-color: var(
    --ld-ref-palette-tertiary98
  );

  --ld-button-border-radius: var(--ld-ref-border-radius-m);
  --ld-button-line-height: var(--ld-ref-line-height);
  --ld-button-font-size: var(--ld-ref-font-size-m);
  --ld-button-font-family: var(--ld-ref-font-family);
  --ld-button-font-weight: var(--ld-ref-font-weight-normal);
  --ld-button-padding-y: var(--ld-ref-spacing-m);
  --ld-button-padding-x: var(--ld-ref-spacing-l);
}

.ButtonRoot {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  //font-family: 'Nunito';
  font-family: var(--ld-button-font-family);
  //font-size: 1rem;;
  font-size: var(--ld-button-font-size);
  //line-height: 1.5;
  line-height: var(--ld-button-line-height);
  //background-color: transparent;
  //border-radius: $borderRadius;
  border-radius: var(--ld-button-border-radius);
  //font-weight: 400;
  font-weight: var(--ld-button-font-weight);
  //padding: 8px 16px;
  padding: var(--ld-button-padding-y) var(--ld-button-padding-x);
  cursor: pointer;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.fullwidth {
    width: 100%;
  }

  &.focusVisible {
    box-shadow: 0 4px 20px 0 rgb(61 71 82 / 0.1), 0 0 0 5px rgb(0 127 255 / 0.5);
    outline: none;
  }

  &.filled {
    color: var(--ld-button-filled-primary-font-color);
    border: solid 1px var(--ld-button-filled-primary-border-color);
    background-color: var(--ld-button-filled-primary-background-color);

    &:hover,
    &.active {
      background-color: var(--ld-button-filled-primary-hover-background-color);
    }

    &.secondary {
      color: var(--ld-button-filled-secondary-font-color);
      border: solid 1px var(--ld-button-filled-secondary-border-color);
      background-color: var(--ld-button-filled-secondary-background-color);

      &:hover,
      &.active {
        background-color: var(
          --ld-button-filled-secondary-hover-background-color
        );
      }
    }

    &.tertiary {
      color: var(--ld-button-filled-tertiary-font-color);
      border: solid 1px var(--ld-button-filled-tertiary-border-color);
      background-color: var(--ld-button-filled-tertiary-background-color);

      &:hover,
      &.active {
        background-color: var(
          --ld-button-filled-tertiary-hover-background-color
        );
      }
    }
  }

  &.outlined {
    border: solid 1px var(--ld-button-outlined-primary-border-color);
    background-color: var(--ld-button-outlined-primary-background-color);
    color: var(--ld-button-outlined-primary-font-color);

    &:hover,
    &.active {
      background-color: var(
        --ld-button-outlined-primary-hover-background-color
      );
    }

    &.secondary {
      border: solid 1px var(--ld-button-outlined-secondary-border-color);
      background-color: var(--ld-button-outlined-secondary-background-color);
      color: var(--ld-button-outlined-secondary-font-color);

      &:hover,
      &.active {
        background-color: var(
          --ld-button-outlined-secondary-hover-background-color
        );
      }
    }

    &.tertiary {
      border: solid 1px var(--ld-button-outlined-tertiary-border-color);
      background-color: var(--ld-button-outlined-tertiary-background-color);
      color: var(--ld-button-outlined-tertiary-font-color);

      &:hover,
      &.active {
        background-color: var(
          --ld-button-outlined-tertiary-hover-background-color
        );
      }
    }
  }

  &.text {
    border: none;
    background: none;
    color: var(--ld-button-text-primary-font-color);

    &:hover {
      background-color: var(--ld-button-text-primary-hover-background-color);
    }

    &.secondary {
      color: var(--ld-button-text-secondary-font-color);

      &:hover {
        background-color: var(
          --ld-button-text-secondary-hover-background-color
        );
      }
    }

    &.tertiary {
      color: var(--ld-button-text-tertiary-font-color);

      &:hover {
        background-color: var(--ld-button-text-tertiary-hover-background-color);
      }
    }
  }
}
