@import '../scss/helper.scss';
@import '../scss/basic.scss';

.mainEvent {
  display: block;
}

.MainEventContainer {
  display: block;
  //height: calc(100vh - 58vh);
  height: auto;
  margin: 20px;

  .carousel {
    display: flex;
    overflow: hidden;
    width: 100%;
  }

  .carouselItem {
    opacity: 1;
    position: relative;
    // top: 0;
    border-radius: 15px;
    // left: 0;
    transition: opacity 0.5s ease-in-out;
  }

  .active {
    opacity: 1;
    z-index: 1;
  }
}
.description {
  margin:1rem;
  max-height:200px;
  overflow:scroll;
  border:1px solid #ddd;
  padding:1rem;
}

.carouselImg {
  top: 0;
  z-index: -1;
  background-size: contain;
  border-radius: 15px;
  width: 100%;
}

.carouselImgLayer {
  z-index: 4;
  width: pxToRem(180px);
}

.carouselDesc {
  color: var(--lincd-color-text-white);
}

.carouselContent {
  padding: 25px;
  flex: 2;
  margin-top: 35px;
  width: 300px;
}


// .bannerOverlay {
//   position: absolute;
//   top: 0;
//   left: 0px;
//   right:20px;
//   width: 100%;
//   background-color: rgba(0, 0, 0, 0.4); /* Adjust opacity as needed */
//   border-radius: 15px;
// }

@media only screen and (min-width: 576px) and (max-width: 767.98px) {
  .carouselContent {
    padding: 25px;
    flex: 2;
    margin-top: 10px;
  }
  .MainInnerContainer {
    margin-top: 60px;
  }
}

.MainInnerContainer {
  display: flex;
  flex-direction: row;
  position: relative;
  top: -380px;
  width: 400px;
  left: 20px;
}

@media only screen and (max-width: 575.98px) {
  .MainInnerContainer {
    display: flex;
    flex-direction: row;
    position: relative;
    margin-top: 80px;
    width: 280px;
    left: 10px;
  }
  // .bannerOverlay {
  //   height:calc(328px - 20px);
  //   border-radius:15px;
  // }
}

.estimatedEvents {
  color: var(--lincd-color-text-white);
  font-size: var(--lincd-font-size-small);
  background: var(--lincd-color-background-transparent);
  padding: 20px;
  display: inline-block;
  position: relative;
  right: -20px;
}

.carouselTitle {
  color: var(--lincd-color-text-white);
}

.dateEvents {
  color: var(--lincd-color-text-white);
  margin-top: 50px;
  font-family: var(--lincd-font-family-primary);
}
