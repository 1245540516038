.Input {
  font-family: var(--lincd-input-inputfield-text-font);
  font-size: var(--lincd-input-inputfield-text-size);
  font-weight: 400;
  line-height: 1.5;
  border-radius: var(--lincd-input-inputfield-border-radius);
  color: var(--lincd-color-primary);
  background-color: var(--lincd-input-inputfield-background-color);
  border-width: var(--lincd-input-inputfield-border-width);
  border-color: var(--lincd-input-inputfield-border-color);
  padding: var(--ld-ref-spacing-m);

  width: 100%;

  //width: 320px;
  //font-family: 'IBM Plex Sans', sans-serif;
  //font-size: 0.875rem;
  //font-weight: 400;
  //line-height: 1.5;
  //padding: 8px 12px;
  //border-radius: 8px;
  //color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  //background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  //border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  //box-shadow: 0px 2px 4px ${
  //  theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.5)' : 'rgba(0,0,0, 0.05)'
  //};

  &:hover {
    border-color: var(--lincd-input-inputfield-hover-border-color);
  }

  &:focus {
    border-color: var(--lincd-input-inputfield-focus-border-color);
    //box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
}
