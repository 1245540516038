:root {
  --ld-checkbox-border-color: #1372ee;
  --ld-checkbox-background-color: #1372ee;
}

.root {
  display: inline-flex;
}

.label {
  display: inline-flex;
  align-items: center;
}

// checkbox type
.input {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;

  /* creating a custom design */
  width: 1.6em;
  height: 1.6em;
  border-radius: 0.15em;
  margin-right: 0.5em;
  margin-bottom: 0 !important;
  border: 0.15em solid var(--ld-checkbox-border-color);
  outline: none;
  cursor: pointer;

  &:disabled {
    border-color: #c0c0c0;
    background-color: #c0c0c0;
  }

  &:disabled + span {
    color: #c0c0c0;
  }

  &:checked {
    background-color: #fff;
    position: relative;
  }

  &:checked::before {
    content: '';
    background: url('/images/check-blue.png') no-repeat center center;
    background-size: contain;
    width: 1em;
    height: 1em;
    font-size: 1.5em;
    color: #fff;
    position: absolute;
    right: -1px;
    top: -2px;
  }
}
