.Home {
  display: block;
  margin: 1rem 0;

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
    margin: 0.5rem 0;

    pre {
      padding: 1rem;
      background-color: #bfe7fc;
    }
  }
}

.container {
  max-width: 600px;
  margin: 0 auto;
}

.seeAll {
  font-size: pxToRem(16px);
  color: #ccc;
}

.PracticionerList {
  position: relative;
  //top: -40px;
  padding: 10px 20px;
  border-radius: 25px 25px 0 0;
  margin: 0 auto;
  z-index: 5;
  background-color: var(--lincd-color-background);
  max-width: 1200px; /* Set a maximum width for the container */
}

.PracticionerContainer {
  list-style: none;
  margin-top: 0;
  padding-left: 0;
  display: grid; /* Use grid layout */
  grid-template-rows: repeat(2, auto); /* Two rows */
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  gap: 20px; /* Add gap between grid items */
}

.flexSide {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 5px 25px auto;
  margin-top: 15px;
  align-self: flex-start;
  margin-bottom: 10px;
  position: relative;
  //top:-30px;
}

.headingPracticioner {
  margin-top: 10px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 575.98px) {
  .HeaderInformation {
    position: relative;
    left: 10px;
    height: 40px;
    z-index: 2;
    display: flex;
    flex-direction: row;
    // margin-top:25px;
    align-items: center;

    .headerTitle {
      color: var(--lincd-primary-gray-500);
      font-size: 22px;
    }
  }
}

.buttonContainerNoFound {
  background-image: linear-gradient(
    var(--lincd-gradient-primary-brand),
    var(--lincd-gradient-secondary-brand)
  );
  width: 95% !important;
  border: 1px solid var(--lincd-button-border-color);
  height: pxToRem(60px);
  color: var(--lincd-color-text-white);
  margin-right: 10px;
  border-radius: pxToRem(10px);
  box-shadow: var(--lincd-color-box-shadow);

  &:hover {
    background: var(--lincd-button-hover-bg);
  }

  &.active {
    box-shadow: var(--lincd-color-box-shadow-inset);
  }
}

.searchFound {
  text-align: center;
}

.HeaderInformation {
  height: 55px;
  z-index: 2;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 25px;
  justify-content: center;

  .headerTitle {
    color: var(--lincd-primary-gray-500);
  }
}

.containerBox {
  padding: 20px;
  margin-top: -15px;
  height: 0;
  box-shadow: 0 10px 9px -5px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 10px 9px -5px rgba(0, 0, 0, 0.15);
  position: relative;
}

.avatarImage {
  display: block;
  height: 75px;
  width: 75px;
  // border: 1px solid var(--lincd-color-primary);
}

.homeSearch {
  width: 98%;
  margin: 5px !important;
  height: 80px;
  padding: 0 15px;
  text-indent: 40px;

  input {
    border: 1px solid #c6c6c6 !important;
    border-radius: 50px !important;
    padding: 20px;
    text-indent: 40px;
    background-color: #f9f9f9;
    width: 100%;
  }

  input::placeholder {
    text-indent: 40px;
  }
}
