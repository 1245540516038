@import '../scss/basic.scss';
@import '../scss/helper';
@import '../App.scss';

.BiometricContainer {
  margin: 20px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-bottom: 3rem;

  button {
    margin-bottom: 15px !important;
  }
}

.ButtonContainer,
.BiometricInfo {
  flex: 1;

  .centerText {
    text-align: center;
    padding-bottom: 20px;

    button {
      margin-left: 0 !important;
    }

    .copyContainer {
      display: flex;
      gap: 5px;
      align-items: center;
      border: 1px solid #222;
      flex-direction: row;
      justify-content: space-between;
      border-style: dashed;
      padding: 10px;
      border-radius: 5px;

      h5 {
        font-size: 1rem;
      }

      .copyIcon {
        width: 30px;
        height: 30px;
        transition: all 0.2s ease-in-out;

        &:hover {
          transform: scale(1.1);
        }

        &:active {
          transform: scale(0.9);
        }
      }
    }

    .CopiedButton {
      background-color: #ffffff;
      color: #222;
    }
  }

  .inputManual {
    margin-top: -10px;
  }
}

.ButtonContainer {
  justify-content: flex-end;
}

.buttonPrimary {
  flex: 1;
  background-image: linear-gradient(
                  var(--lincd-gradient-primary-brand),
                  var(--lincd-gradient-secondary-brand)
  );
  width: 100%;
  border: 1px solid var(--lincd-button-border-color);
  height: pxToRem(60px);
  color: var(--lincd-color-text-white);
  margin: 0 !important;
  border-radius: pxToRem(10px);
  box-shadow: var(--lincd-color-box-shadow);

  &:hover {
    background: var(--lincd-button-hover-bg);
  }

  &.active {
    box-shadow: var(--lincd-color-box-shadow-inset);
  }
}

.BiometricUser {
  display: block;
  width: 150px;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  height: 100px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 100px;
  }
}

.BiometricInner {
  display: flex;
  flex-direction: row;

  h1 {
    color: var(--lincd-color-primary);
    font-weight: var(--lincd-font-weight-regular);
  }
}

.BiometricImageContainer {
  justify-content: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
  }

  .BiometricImage {
    margin-top: 50px;
    width: 230px;
    height: 230px;
  }

  .biometricAuth,
  .googleAuth {
    background-color: #fff;
    color: #222;
    border-radius: 50px;
    box-shadow: none !important;
    height: 42px;
    border: 1px solid #ccc;
  }
}

.notes {
  flex: 1;
  background-image: linear-gradient(
                  var(--lincd-gradient-primary-brand-white),
                  var(--lincd-gradient-secondary-brand-white)
  );
  border: 1px solid var(--lincd-color-text-muted) !important;
  color: #222;
  padding: 0 !important;
  margin: 0 !important;

  &:hover {
    background-image: linear-gradient(
                    var(--lincd-color-green-400),
                    var(--lincd-color-green-800)
    );
  }
}

.BiometricPopup {
  height: 100vh;
  position: absolute;
  top: 0;
  display: flex;
  width: 100%;
  background: #2e2e2e73;
  border: 1px solid #eee;
  flex-direction: column;
  border-radius: 20px;
  align-items: center;
  justify-content: center;

  .BiometricPopupInner {
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 20px;
    padding: 20px;
    flex-direction: column;
    display: flex;
    align-items: center;
    position: relative;
    text-align: center;
    padding-bottom: 30px;
    justify-content: center;

    .closeButton {
      position: absolute;
      right: 20px;
      top: 0;
    }

    button {
      width: 95% !important;
      padding: 15px;
      margin-top: 40px !important;
    }

    img {
      padding-top: 20px;
    }
  }
}
