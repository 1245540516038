// List.scss
@import '../scss/_helper';
@import '../scss/basic.scss';

.container {
  margin: 20px;
  display: flex;
  flex-direction: row;
}

.alphabetIndex {
  z-index: 2;
  border-radius: 4px;
  padding: 8px; // Add padding for better spacing
  p {
    font-size: 10px;
  }
}

.contactList {
  list-style: none;
  padding: 0;
  margin: 0;
  flex: 2;
}

.contactItem {
  // border: 1px solid #ccc;
  padding: 15px 20px;
  margin: 10px 0;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  cursor: pointer;
  max-width: 80vw;
  overflow: hidden;

  .contactPicture {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 15px;
    min-width: 60px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .contactDetails {
    flex: 1;
    overflow: hidden;

    .contactName {
      margin: 0;
    }

    .contactCompany {
      margin: 5px 0;
      color: var(--lincd-color-primary);
    }

    .contactProfession {
      margin: 5px 0;
      color: var(--lincd-color-text-black);
    }
  }
}
