.DividerRoot {
  position: relative;
  margin: 2rem 0;

  hr {
    border-width: 0;
    height: 1px;
    width: 100%;
    background-color: #e3e3e3;
  }

  p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 1rem;
    margin: 0;
    background-color: #fff;
    color: #747474;
    z-index: 1;
  }
}
