@import '../scss/basic.scss';
@import '../scss/_helper.scss';

.header {
  display: block;
  z-index: 5;
  position: relative;
}

.connectionsHeader {
  z-index: 9;
}

.backNavigator {
  padding: 0 15px;

  :hover {
    cursor: pointer;
  }
}

.blackNavText {
  color: var(--lincd-color-text-black);
  font-size: pxToRem(22px);
  margin: 0 40px 0 0;
}

.whiteNavText {
  color: var(--lincd-color-text-white);
  font-size: pxToRem(22px);
  margin-right: 20px;
}

.menu {
  margin: pxToRem(20px) 0;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 1rem;
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding-top: 10px;
  min-height: 56px;
}

// .menuBar {
//   position: absolute;
//   left: 30px;
//   top: 20px;
// }

.navigation {
  position: absolute;
  background-color: var(--lincd-color-background);
  width: 180px;
  padding: 25px;

  a {
    display: inline-flex;
    padding: 0.25rem 0;
    color: var(--lincd-color-text-black);
    text-decoration: none;
    margin-top: 10px;
  }
}
