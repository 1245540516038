@import '../scss/helper.scss';
@import '../scss/basic.scss';

.Container {
  margin: 0 auto;
  max-width: 600px;
  overflow: auto;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  //top:-40px;
  // border: 1px solid var(--lincd-color-text-muted);
}

.ContainerInner {
  padding: 15px;
  padding-top: 0;
}

h3 {
  font-size: var(--lincd-font-size-heading2);
  color: var(--lincd-color-text-black);
  margin-top: 15px;
}

.contentParagraph {
  color: var(--lincd-color-text-black);
  font-family: var(--lincd-font-family-primer);
  font-weight: var(--lincd-font-weight-regular);
  color: var(--lincd-color-text-black);
  margin: 15px 0 15px 0;
  line-height: 30px;
  margin-top: 25px;
  margin-bottom: 25px;
}

.profesiNameWrap {
  margin: 15px 0 10px;
}

.verified {
  display: flex;
  align-items: center;
  margin-top: 5px;

  p {
    margin: 0;
    font-size: var(--lincd-font-size-small);
    color: var(--ld-app-color-primary);
  }
}

.ProfileName {
  margin: 0;
}

.targetProfile {
  list-style-type: none;
  margin-left: -10px;
}

.imageProfile {
  //width: 150px !important;
  //height: 150px !important;
  //background-size: cover;
  width: 100%;
  border-radius: 10px;
  //height: 380px;
}

.profession {
  color: var(--lincd-color-primary);
  margin: 0;
}

.flexSide {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flexColumn {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}

.imgIcon {
  width: 32px;
  height: 32px;
}

.imgIconButton {
  width: 28px;
  height: 28px;
  margin-right: 10px;
}

.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    margin-left: 10px;
    font-size: pxToRem(18px);
  }
}

button.connect {
  background-image: linear-gradient(
    var(--lincd-gradient-primary-brand-white),
    var(--lincd-gradient-secondary-brand-white)
  );
  border: 1px solid var(--lincd-color-text-muted) !important;
  color: var(--lincd-color-text-black);
  padding: 0 !important;
  font-size: pxToRem(18px);
  margin: 0 !important;
  margin-right: 15px !important;

  &:hover {
    background-image: linear-gradient(
      var(--lincd-color-green-400),
      var(--lincd-color-green-800)
    );
  }
}

button.connected {
  background-image: linear-gradient(
    var(--lincd-color-green-600),
    var(--lincd-color-green-900)
  );
  border: 1px solid var(--lincd-color-text-muted) !important;
  color: var(--lincd-color-text-white);
  padding: 0 !important;
  font-size: pxToRem(18px);
  margin: 0 !important;
  margin-right: 15px !important;

  &:hover {
    background-image: linear-gradient(
      var(--lincd-color-green-400),
      var(--lincd-color-green-800)
    );
  }
}

button.notes {
  background-image: linear-gradient(
    var(--lincd-gradient-primary-brand),
    var(--lincd-gradient-secondary-brand)
  );
  font-size: pxToRem(18px);
  padding: 0 !important;
  margin: 0 !important;

  border: 1px solid var(--lincd-color-tertiary) !important;
  color: var(--lincd-color-text-white);

  &:hover {
    background-image: linear-gradient(
      var(--lincd-color-green-400),
      var(--lincd-color-green-800)
    );
  }
}

.orgDetails {
  //float:right;
  display: flex;
  margin: 1rem 0;
  align-items: center;

  .businessLogo {
    display: inline-block;
    border-radius: 10px;
  }

  .orgName {
    vertical-align: center;
    margin-left: 10px;
  }
}

.verifiedIcon {
  width: 22px;
  height: 22px;
  margin-left: 5px;
  display: inline-block;
}
