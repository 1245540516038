:root {
  --lincd-magiclogin-input-border-radius: 9999px;
  --lincd-magiclogin-input-background-color: var(--lincd-color-background);
  --lincd-magiclogin-input-border: solid 1px var(--ld-ref-palette-primary30);
  --lincd-magiclogin-input-border-hover: solid 1px
    var(--ld-ref-palette-primary50);
}

.layout {
  min-height: 100vh;
}

.Root {
  display: flex;
  position: relative;
  height: calc(100vh - var(--safe-area-bottom, 0px));
}

.el_input {
  border: 1px solid var(--lincd-color-primary);
  border-radius: 100px;
}

.background {
  min-height: 50vh;
  background-color: #f5f6f7;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 50vh;
}

.content {
  background-color: #fff;
  border-radius: 25px 25px 0 0;
  box-shadow: 0 -15px 20px rgba(0, 0, 0, 0.2);
  position: relative;
  //height:calc(100vh - 48vh);
  align-self: flex-end;
  margin: 0 auto;
  max-width: 600px;
}

.wrapper {
  padding: 2rem 1.5rem;
}

.magicLoginButton {
  button {
    width: 36px !important;
    height: 36px !important;
    margin: 0 !important;
    background-color: var(--ld-ref-palette-primary50);
    color: #fff;
  }

  div {
    form {
      max-width: 100% !important;

      input {
        color: var(--ld-ref-palette-neutral25);
      }
    }

    // create account and forgot password text
    p {
      margin: 0;
    }
  }

  input {
    padding: 0.8rem 1rem;
  }

  // signin with password
  .emailButton {
    width: 100% !important;
    height: 55px !important;
    background-color: var(--lincd-color-primary) !important;
    color: #fff !important;
  }
}

.buttons {
  display: flex;
  gap: 1rem;
  margin-bottom: 20px;
  // flex-direction: column;
  width: min-content;
  margin: 0 auto;
}

.oauthButton {
  //width: auto !important;
  width: 50px !important;
  // height: auto !important;
  margin: 0 !important;
  padding: 6px 0;
  box-shadow: none !important;
  border:0px !important;
}

.logo {
  padding-left: 2rem;
  padding-right: 2rem;
  margin: 0 auto 1rem;
  max-width: 400px;
}

.info {
  margin-bottom: 1rem;
}
