.EventList {
  display: block;
}

.containerEvent {
  display: block;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 18px;
  z-index: 1; /* Ensure the overlay appears above the image */
}

.SpinnerEvent {
  margin: 50px auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.event {
  display: block;
  box-shadow: 0 5px 10px #cdcdcd;
  border-radius: 12px;
  padding: 10px;
  margin-bottom: 15px;
  // max-width: 380px;

  &__completed {
    background-color: red;
  }

  &__pictureContainer {
    position: relative;
  }

  &__picture {
    min-width: 100%;
    width: 100%;
    // height: 180px;
    border-radius: 12px;
    object-fit: cover;
    background-size: cover;
    background-position: center;
  }

  &__details {
    display: flex;
    color: #757575;
    justify-content: space-between;
    line-height: none;
    padding: 0 10px;
    align-items: center;
    gap: 20px;
  }

  &__title {
    color: #222222;

    p {
      color: var(--lincd-primary-gray-500);
    }
  }

  &__descContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  &__desc {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__place {
    position: absolute;
    bottom: 0;
    left: 20px;
    background-color: rgba(255, 255, 255, 0.66);
    padding: 10px 20px;
    color: #3d3d3d;
    border-radius: 30px;
  }

  &__users {
    display: flex;
    position: absolute;
    bottom: 20px;
    right: 20px;
  }

  &__userPhoto {
    box-sizing: content-box;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    border: 3px solid white;

    &:not(:last-child) {
      margin-right: -8px;
    }
  }

  &__icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;

    & > svg:first-child {
      width: 30px;
      height: 30px;

      &:hover {
        cursor: pointer;
      }
    }

    & > svg:last-child {
      width: 60px;
      height: 60px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
