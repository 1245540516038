.Event {
  display: block;
}

.eventListing {
  display: grid;
  gap: 1rem;

  @media screen and (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }

  :hover {
    cursor: pointer;
  }
}

.header {
  // background-image: #0068f9, url('images/header.avif') no-repeat top center;
  // background-image: linear-gradient(
  //     to right bottom,
  //     rgba(0, 104, 249, 0.8),
  //     rgba(1, 94, 225, 0.8)
  //   ),
  //   url('/emergent-lives/web/images/header.avif');
  background: url('/images/headerBg.jpg');
  background-size: cover;
  padding: 30px 20px;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__text {
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;

    @media screen and (min-width: 1024px) {
      padding: 0 20px;
    }

    h2 {
      color: white;
    }

    p {
      color: #aea9b1;
    }
  }

  &__userPhoto {
    box-sizing: content-box;
    height: 80px;
    width: 80px;
    border-radius: 50%;
  }
}

.container {
  border-radius: 30px;
  padding: 20px;
  // box-shadow: 0 5px 10px #cdcdcd;
  border-radius: 30px;
  z-index: 5;
  max-width: 1024px;
  margin: 0 auto;
}
