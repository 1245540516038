.modalButtonContainer {
  display: flex;
  justify-content: flex-end !important;
  gap: 10px;
  margin-top: 20px;
}
.modal {
  padding: 1rem;
  max-width: min(480px, 90vw);
}
