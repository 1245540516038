.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  box-sizing: border-box;
  padding: 1.5rem;
  height: 100vh;

  form {
    min-width: min(420px, 90vw);
    display: flex;
    gap: 15px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .FormGroup {
    display: flex;
    flex-direction: column;
    gap: 8px;
    box-sizing: border-box;
    width: 100%;

    .ErrorMessage {
      font-size: 12px;
      color: var(--ld-auth-login-text-error);
    }
  }

  .FormButton {
    padding: 15px 10px;
    margin: 0;
    background-color: steelblue;
    color: white;

    &:hover {
      background-color: steelblue;
      color: white;
    }
  }
}
