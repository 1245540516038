.layout {
  min-height: 100vh;
}

.Root {
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  max-width: 500px;
  margin: 0 auto;
}

.wrapper {
  position: relative;
  align-self: flex-end;
  padding: 2rem;
}

.logo {
  padding-left: 2rem;
  padding-right: 2rem;

  p {
    color: #7f888d;
    text-transform: uppercase;
    font-weight: 500;
  }
}

.started {
  display: block;
  text-align: center;

  p {
    color: #8f8f8f;
  }

  .button {
    margin: 0 !important;
    background-image: linear-gradient(
      var(--lincd-gradient-primary-brand),
      var(--lincd-gradient-secondary-brand)
    );
    font-size: pxToRem(20px);
    color: var(--lincd-color-text-white);
    border: 1px solid var(--lincd-color-tertiary) !important;
    color: var(--lincd-color-text-white);
  }

  .info {
    margin-top: 2rem;
    color: #141414;
    font-size: var(--lincd-font-size-small);
    line-height: var(--lincd-line-height-normal);
  }
}
