.toggleSwitch {
  position: relative;
  width: 60px;
  height: 30px;
  background-color: #ccc;
  border-radius: 15px;
  cursor: pointer;
}

.checked {
  background-color: var(--lincd-toggle-switch-button-background-color-active);
}

.toggleInput {
  position: absolute;
  opacity: 0;
}

.toggleButton {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 28px;
  height: 28px;
  background-color: var(--lincd-color-text-white);
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.toggleSwitch.checked .toggleButton {
  transform: translateX(30px);
  background-image: linear-gradient(
                  var(--lincd-gradient-primary-brand),
                  var(--lincd-gradient-secondary-brand)
  );
}
