.subtitle {
  color: #aea9b1;
}

// .layout {
//   min-height: 100vh;
// }

.wrapper {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  max-width: 600px;
  margin: 0 auto;
}

.headline {
  font-size: 2rem;
}
