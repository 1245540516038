.EnableAuthenticatorButton {
  display: block;
}
.googleAuth {
  background-color: #fff !important;
  color: #222 !important;
  border-radius: 50px;
  box-shadow: none !important;
  height: 42px;
  border: 1px solid #ccc !important;
}
.checkMark {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 350px;
}
