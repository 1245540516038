@import '../scss/basic.scss';
@import '../scss/helper';
@import '../App.scss';

.BiometricContainer {
  margin: 20px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-bottom: 3rem;

  button {
    margin-bottom: 15px !important;
  }
}

.ButtonContainer,
.BiometricInfo {
  flex: 1;
}

.ButtonContainer {
  justify-content: flex-end;
}

.buttonPrimary {
  flex: 1;
  background-image: linear-gradient(
                  var(--lincd-gradient-primary-brand),
                  var(--lincd-gradient-secondary-brand)
  );
  width: 100%;
  border: 1px solid var(--lincd-button-border-color);
  height: pxToRem(60px);
  color: var(--lincd-color-text-white);
  margin: 0 !important;
  border-radius: pxToRem(10px);
  box-shadow: var(--lincd-color-box-shadow);

  &:hover {
    background: var(--lincd-button-hover-bg);
  }

  &.active {
    box-shadow: var(--lincd-color-box-shadow-inset);
  }
}

.BiometricUser {
  display: block;
  width: 150px;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  height: 100px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 100px;
  }
}

.BiometricInner {
  display: flex;
  flex-direction: row;

  h1 {
    color: var(--lincd-color-primary);
    font-weight: var(--lincd-font-weight-regular);
  }
}

.BiometricImageContainer {
  justify-content: center;
  flex-direction: column;
  display: flex;
  align-items: center;

  .BiometricImage {
    margin-top: 50px;
    width: 230px;
    height: 230px;
  }

  .biometricAuth,
  .googleAuth {
    background-color: #fff;
    color: #222;
    border-radius: 50px;
    box-shadow: none !important;
    height: 42px;
    border: 1px solid #ccc;

    img {
      width: 20px;
      height: 20px;
      position: absolute;
      left: 350px;
    }
  }
}

.notes {
  flex: 1;
  background-image: linear-gradient(
                  var(--lincd-gradient-primary-brand-white),
                  var(--lincd-gradient-secondary-brand-white)
  );
  border: 1px solid var(--lincd-color-text-muted) !important;
  color: #222;
  padding: 0 !important;
  margin: 0 !important;

  &:hover {
    background-image: linear-gradient(
                    var(--lincd-color-green-400),
                    var(--lincd-color-green-800)
    );
  }
}

.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;

  animation: modalAppear 0.5s ease-in-out forwards;

  .modalOverlay {
    height: 100vh;
    width: 100%;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
  }

  .modalContent {
    background-color: #fff;
    justify-content: center;
    align-items: center;
    margin: 30px;
    border-radius: 10px;
    border: 1px solid #eee;
    padding: 20px;

    img {
      left: 0;
      top: 10px;
      right: 0;
      position: absolute;
      margin: 0 auto;
    }

    h4 {
      padding-top: 20px;
      text-align: center;
    }
  }
}

@keyframes modalAppear {
  0% {
    transform: translateY(-100%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(10%);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
}
