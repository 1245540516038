@import 'lincd/lib/scss/variables.scss';

:root {
  --ld-source: #1372ee;
  /* primary */
  --ld-ref-palette-primary0: #000000;
  --ld-ref-palette-primary10: #001a42;
  --ld-ref-palette-primary20: #002e6a;
  --ld-ref-palette-primary25: #00397f;
  --ld-ref-palette-primary30: #004395;
  --ld-ref-palette-primary35: #004fac;
  --ld-ref-palette-primary40: #005ac3;
  --ld-ref-palette-primary50: #1472ee;
  --ld-ref-palette-primary60: #4e8eff;
  --ld-ref-palette-primary70: #81aaff;
  --ld-ref-palette-primary80: #aec6ff;
  --ld-ref-palette-primary90: #d8e2ff;
  --ld-ref-palette-primary95: #edf0ff;
  --ld-ref-palette-primary98: #f9f9ff;
  --ld-ref-palette-primary99: #fefbff;
  --ld-ref-palette-primary100: #ffffff;
  /* secondary */
  --ld-ref-palette-secondary0: #000000;
  --ld-ref-palette-secondary10: #001d34;
  --ld-ref-palette-secondary20: #003355;
  --ld-ref-palette-secondary25: #003e67;
  --ld-ref-palette-secondary30: #004a79;
  --ld-ref-palette-secondary35: #00568c;
  --ld-ref-palette-secondary40: #00629f;
  --ld-ref-palette-secondary50: #2a7bbd;
  --ld-ref-palette-secondary60: #4b95d8;
  --ld-ref-palette-secondary70: #69b0f5;
  --ld-ref-palette-secondary80: #9acbff;
  --ld-ref-palette-secondary90: #d0e4ff;
  --ld-ref-palette-secondary95: #e9f1ff;
  --ld-ref-palette-secondary98: #f8f9ff;
  --ld-ref-palette-secondary99: #fcfcff;
  --ld-ref-palette-secondary100: #ffffff;
  /* tertiary */
  --ld-ref-palette-tertiary0: #000000;
  --ld-ref-palette-tertiary10: #001c3a;
  --ld-ref-palette-tertiary20: #00315f;
  --ld-ref-palette-tertiary25: #003c72;
  --ld-ref-palette-tertiary30: #004786;
  --ld-ref-palette-tertiary35: #00539a;
  --ld-ref-palette-tertiary40: #005faf;
  --ld-ref-palette-tertiary50: #2678d1;
  --ld-ref-palette-tertiary60: #4992ed;
  --ld-ref-palette-tertiary70: #72adff;
  --ld-ref-palette-tertiary80: #a6c8ff;
  --ld-ref-palette-tertiary90: #d4e3ff;
  --ld-ref-palette-tertiary95: #ebf1ff;
  --ld-ref-palette-tertiary98: #f9f9ff;
  --ld-ref-palette-tertiary99: #fdfcff;
  --ld-ref-palette-tertiary100: #ffffff;
  /* neutral */
  --ld-ref-palette-neutral0: #000000;
  --ld-ref-palette-neutral10: #1b1b1f;
  --ld-ref-palette-neutral20: #303034;
  --ld-ref-palette-neutral25: #3b3b3f;
  --ld-ref-palette-neutral30: #46464a;
  --ld-ref-palette-neutral35: #525256;
  --ld-ref-palette-neutral40: #5e5e62;
  --ld-ref-palette-neutral50: #77777a;
  --ld-ref-palette-neutral60: #919094;
  --ld-ref-palette-neutral70: #ababaf;
  --ld-ref-palette-neutral80: #c7c6ca;
  --ld-ref-palette-neutral90: #e3e2e6;
  --ld-ref-palette-neutral95: #f2f0f4;
  --ld-ref-palette-neutral98: #faf8fd;
  --ld-ref-palette-neutral99: #fefbff;
  --ld-ref-palette-neutral100: #ffffff;
  /* neutral-variant */
  --ld-ref-palette-neutral-variant0: #000000;
  --ld-ref-palette-neutral-variant10: #191b22;
  --ld-ref-palette-neutral-variant20: #2e3038;
  --ld-ref-palette-neutral-variant25: #393b43;
  --ld-ref-palette-neutral-variant30: #44474f;
  --ld-ref-palette-neutral-variant35: #50525a;
  --ld-ref-palette-neutral-variant40: #5c5e66;
  --ld-ref-palette-neutral-variant50: #75777f;
  --ld-ref-palette-neutral-variant60: #8e9099;
  --ld-ref-palette-neutral-variant70: #a9abb4;
  --ld-ref-palette-neutral-variant80: #c5c6d0;
  --ld-ref-palette-neutral-variant90: #e1e2ec;
  --ld-ref-palette-neutral-variant95: #eff0fa;
  --ld-ref-palette-neutral-variant98: #f9f9ff;
  --ld-ref-palette-neutral-variant99: #fefbff;
  --ld-ref-palette-neutral-variant100: #ffffff;
  /* error */
  --ld-ref-palette-error0: #000000;
  --ld-ref-palette-error10: #410002;
  --ld-ref-palette-error20: #690005;
  --ld-ref-palette-error25: #7e0007;
  --ld-ref-palette-error30: #93000a;
  --ld-ref-palette-error35: #a80710;
  --ld-ref-palette-error40: #ba1a1a;
  --ld-ref-palette-error50: #de3730;
  --ld-ref-palette-error60: #ff5449;
  --ld-ref-palette-error70: #ff897d;
  --ld-ref-palette-error80: #ffb4ab;
  --ld-ref-palette-error90: #ffdad6;
  --ld-ref-palette-error95: #ffedea;
  --ld-ref-palette-error98: #fff8f7;
  --ld-ref-palette-error99: #fffbff;
  --ld-ref-palette-error100: #ffffff;
  /* light */
  --ld-app-color-primary: #005ac3;
  --ld-app-color-on-primary: #ffffff;
  --ld-app-color-primary-container: #d8e2ff;
  --ld-app-color-on-primary-container: #001a42;
  --ld-app-color-secondary: #00629f;
  --ld-app-color-on-secondary: #ffffff;
  --ld-app-color-secondary-container: #d0e4ff;
  --ld-app-color-on-secondary-container: #001d34;
  --ld-app-color-tertiary: #005faf;
  --ld-app-color-on-tertiary: #ffffff;
  --ld-app-color-tertiary-container: #d4e3ff;
  --ld-app-color-on-tertiary-container: #001c3a;
  --ld-app-color-error: #ba1a1a;
  --ld-app-color-error-container: #ffdad6;
  --ld-app-color-on-error: #ffffff;
  --ld-app-color-on-error-container: #410002;
  --ld-app-color-background: #fefbff;
  --ld-app-color-on-background: #1b1b1f;
  --ld-app-color-surface: #fefbff;
  --ld-app-color-on-surface: #1b1b1f;
  --ld-app-color-surface-variant: #e1e2ec;
  --ld-app-color-on-surface-variant: #44474f;
  --ld-app-color-outline: #75777f;
  --ld-app-color-inverse-on-surface: #f2f0f4;
  --ld-app-color-inverse-surface: #303034;
  --ld-app-color-inverse-primary: #aec6ff;
  --ld-app-color-shadow: #000000;
  --ld-app-color-surface-tint: #005ac3;
  --ld-app-color-outline-variant: #c5c6d0;
  --ld-app-color-scrim: #000000;
  /* dark */
  --ld-app-color-primary-dark: #aec6ff;
  --ld-app-color-on-primary-dark: #002e6a;
  --ld-app-color-primary-container-dark: #004395;
  --ld-app-color-on-primary-container-dark: #d8e2ff;
  --ld-app-color-secondary-dark: #9acbff;
  --ld-app-color-on-secondary-dark: #003355;
  --ld-app-color-secondary-container-dark: #004a79;
  --ld-app-color-on-secondary-container-dark: #d0e4ff;
  --ld-app-color-tertiary-dark: #a6c8ff;
  --ld-app-color-on-tertiary-dark: #00315f;
  --ld-app-color-tertiary-container-dark: #004786;
  --ld-app-color-on-tertiary-container-dark: #d4e3ff;
  --ld-app-color-error-dark: #ffb4ab;
  --ld-app-color-error-container-dark: #93000a;
  --ld-app-color-on-error-dark: #690005;
  --ld-app-color-on-error-container-dark: #ffdad6;
  --ld-app-color-background-dark: #1b1b1f;
  --ld-app-color-on-background-dark: #e3e2e6;
  --ld-app-color-surface-dark: #1b1b1f;
  --ld-app-color-on-surface-dark: #e3e2e6;
  --ld-app-color-surface-variant-dark: #44474f;
  --ld-app-color-on-surface-variant-dark: #c5c6d0;
  --ld-app-color-outline-dark: #8e9099;
  --ld-app-color-inverse-on-surface-dark: #1b1b1f;
  --ld-app-color-inverse-surface-dark: #e3e2e6;
  --ld-app-color-inverse-primary-dark: #005ac3;
  --ld-app-color-shadow-dark: #000000;
  --ld-app-color-surface-tint-dark: #aec6ff;
  --ld-app-color-outline-variant-dark: #44474f;
  --ld-app-color-scrim-dark: #000000;

}
