@import '../scss/helper';
@import '../scss/basic.scss';

.containerBackground {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}

.actionSheet {
  background-color: var(--lincd-color-background);
  border: 1px solid #ccc;
  position: fixed;
  height: 540px;
  width: 100%;
  border-radius: 35px 35px 0 0;
  padding: 40px;
  left: -10px;
  margin: 0 auto;
  right: 0;
  z-index: 9;
  bottom: -500px;
  transition: transform 0.3s ease-in-out;
  /* Add background overlay using ::after pseudo-class */
}

.actionSheet.active {
  transform: translateY(
                  -480px
  ); /* Move the box up by 100px when the 'active' class is applied */
}

.image {
  width: 280px;
  height: 280px;
  align-items: center;
}

.buttonActionSheet {
  background-image: linear-gradient(
                  var(--lincd-gradient-primary-brand),
                  var(--lincd-gradient-secondary-brand)
  );
  border: 1px solid var(--lincd-color-text-muted) !important;
  color: var(--lincd-color-text-white) !important;
  padding: 0 !important;
  width: 100% !important;
  color: var(--lincd-color-text-white);
  margin: 0 !important;
  margin-right: 15px !important;
}

.imageContainer {
  text-align: center;
}

.heading {
  font-family: var(--lincd-font-family-primary);
  text-align: center;
}

.paragraph {
  font-family: var(--lincd-font-family-primary);
  font-weight: var(--lincd-font-weight-regular);
  color: var(--lincd-color-gray-500);
  text-align: center;
}
