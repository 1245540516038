:root {
  --lincd-input-background-color: white;
  --lincd-input-textfield-border-radius: var(--ld-auth-form-border-radius);

  --ld-auth-login-text-hyperlink: var(--ld-auth-text-hyperlink);
  --ld-auth-login-text-error: var(--ld-auth-text-color-error);
  --ld-auth-login-modal-border-radius: var(--ld-auth-modal-border-radius);
  --ld-auth-login-modal-background-color: var(--ld-auth-modal-background-color);
}

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.form {
  width: 100%;

  .hidden {
    display: none;
  }

  .iconButton {
    width: 25px;
    height: 25px;
    margin-right: 15px;
  }

  .errorMessage {
    font-size: 16px;
    margin: 5px 0;
    color: var(--ld-auth-login-text-error);
  }
}

.modalForm {
  padding: 30px 20px;
  border-radius: 20px;
  box-shadow: 2px 2px 6px rgba(176, 176, 176, 0.5);
  background-color: var(--ld-auth-login-modal-background-color);
}

.control {
  position: relative;
}

.end {
  padding: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: red;
  color: steelblue;
  cursor: pointer;
  padding: 0;
  border-radius: 50% !important;
  width: 50px;
}

.bottomSection {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;

  &.ExtraSpace {
    padding-top: 15px;
  }
}

.createAccount,
.forgotPassword {
  cursor: pointer;
  color: var(--ld-auth-login-text-hyperlink);
}

// TODO: check the impact after disable color and text-decoration for the peace game
p.createAccount,
p.forgotPassword {
  // color: #888;
  font-size: 14px;
  padding-top: 5px;
  // text-decoration: underline;
}

.spinner {
  path {
    transform-origin: center;
    animation: spinner 0.75s infinite linear;
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
