@import '../scss/helper.scss';
@import '../scss/basic.scss';

.Container {
  margin: 0 auto;
  width: var(--lincd-container-min-width);
  // border-radius: 0 0 20px 20px;
  box-shadow: 0 -5px 10px 0px rgba(0, 0, 0, 0.1);
  border-top: 1px solid #ebebeb;
  background-color: var(--lincd-color-background);
  position: fixed;
  left: 0;
  height: var(--footer-height);
  z-index: 6;
  // provide a default value (0px) if --safe-area-bottom is not defined
  bottom: calc(var(--safe-area-bottom, 0px));
}

.title {
  font-family: var(--lincd-font-family-primary);
  color: var(--lincd-color-text-black);
  font-weight: var(--lincd-font-weight-regular);
  margin-top: 5px;
  margin-left: 0;
}

.imgSrc {
  height: pxToRem(44px);
  width: pxToRem(44px);
}

.imgScan {
  height: 100%;
  width: 100%;
  padding: 5px;
  border-radius: 100px;
}

.scanLoader {
  // background-image: linear-gradient(
  //   var(--lincd-gradient-primary-brand),
  //   var(--lincd-gradient-secondary-brand)
  // );
  background-color: #fff;
  padding: 20px;
  border: 1px solid var(--lincd-color-text-muted);
  height: pxToRem(82px);
  width: pxToRem(82px);
  border-radius: 100px;
  margin: 0 !important;
  padding: 0 !important;
  position: relative;
  top: -32px;
}

.listFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 0;
  margin: 0;
  margin-top: -10px;
}

.footer {
  list-style: none;
  flex: 1;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &:hover p.title {
    color: var(--lincd-color-primary);
  }

  .active {
    color: var(--lincd-color-primary);
  }
}

.footer:nth-child(3) {
  p {
    position: relative;
    top: -18px;
    font-size: pxToRem(20px);
  }
}
