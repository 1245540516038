@import '../scss/basic.scss';
@import '../scss/helper';

body.scanner-active {
  --background: white;
  --ion-background-color: white;
}

.ContainerQR {
  display: flex;
  height: 100vh;
  flex-direction: column;
  position: relative;
}

.cardWarning {
  position: absolute;
  bottom: 43%;
  margin-left: 20px;
  z-index: 1;
  width: calc(100% - 15%);
}

.flexContainer {
  align-items: center;
  display: flex;
}

.cardFlexing {
  display: flex;
}

.flexActive {
  flex: 1;
}

.cardHeading {
  color: var(--lincd-color-text-white);
  font-family: var(--lincd-font-family-primary);
  margin: 0;
}

.imgIconCamera {
  height: pxToRem(38px);
  width: pxToRem(38px);
}

.overideHeader {
  width: 100% !important;
}

.hrColor {
  background-color: var(--lincd-color-text-white);
  margin-left: 15px;
  width: 100%;
}

.no-margin {
  margin: 0;
}

.fullDisplay {
  flex: 2;
}

.imgIcon {
  height: pxToRem(42px);
  width: pxToRem(42px);
}

.flexSide {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.flexTitle {
  align-items: center;
}

.cardSheet {
  border-radius: 30px 30px 0 0;
  padding: 0 pxToRem(30px) 0 pxToRem(30px);
  position: fixed;
  bottom: 90px;
  right: 0;
  left: 0;
  height: pxToRem(220px);
  width: 100vw;
  margin: 0 auto;
  z-index: 5;
  background: var(--lincd-button-gradient-start);
}

@media only screen and (min-width: 321px) and (max-width: 767px) {
  .cardSheet {
    border-radius: 30px 30px 0 0;
    padding: 0 pxToRem(30px) 0 pxToRem(30px);
    position: fixed;
    bottom: 90px;
    right: 0;
    left: 0;
    height: pxToRem(220px);
    width: 100vw;
    margin: 0 auto;
    z-index: 5;
    background: var(--lincd-button-gradient-start);
  }
}

.halfWidth {
  width: pxToRem(200px);
}

.containerScanner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  right: 0;
  height: calc(100vh - 30vh);

  &img {
    width: 600px;
  }
}

.cardOr {
  color: var(--lincd-color-text-white);
}

.separator {
  border-color: var(--lincd-color-primary);
  margin: pxToRem(30px) 0 pxToRem(30px) 0;
}

p.cardParagraph {
  color: var(--lincd-color-text-white);
  flex: 2;
}

.cardTitle {
  color: var(--lincd-color-text-white);
  font-family: var(--lincd-font-family-primary);
  margin-top: 30px;
}

.IconImg {
  width: pxToRem(30px);
  height: pxToRem(30px);
}
