.connectionContainer {
  max-width: 600px;
  margin: auto;

  h3 {
    top: 15px;
    position: relative;
    margin-left: 20px !important;
  }
}

.noContact {
  text-align: center;
  padding: 0 2.5rem;
}
