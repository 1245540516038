:root {
  --ld-safearea-z-index: 1045;
}

.root {
  position: relative;
  min-height: 100vh;

  // safearea for handle padding side on mobile
  padding-top: var(--safe-area-top);
  padding-right: var(--safe-area-right);
  padding-left: var(--safe-area-left);
  padding-bottom: var(--safe-area-bottom);

  &.resetTop {
    padding-top: 0;
  }

  &.resetBottom {
    padding-bottom: 0;
  }
}

.safearea {
  position: fixed;
  left: 0;
  width: 100%;
  z-index: var(--ld-safearea-z-index);

  &.top {
    top: 0;
    height: var(--safe-area-top);
  }

  &.bottom {
    bottom: 0;
    height: var(--safe-area-bottom);
  }
}
