:root {
  --ld-button-filled-primary-hover-background-color: var(
    --ld-ref-palette-primary50
  );

  --ld-button-border-radius: var(--ld-ref-border-radius-xl);
  --ld-button-filled-secondary-background-color: var(
    --ld-ref-palette-neutral-variant90
  );
  --ld-button-filled-secondary-border-color: var(
    --ld-ref-palette-neutral-variant90
  );
  --ld-button-filled-secondary-font-color: var(
    --ld-ref-palette-neutral-variant20
  );
  --ld-button-filled-secondary-hover-background-color: var(
    --ld-ref-palette-neutral-variant80
  );

  // SigninWithPasswordForm
  --ld-auth-text-color-error: var(--ld-ref-palette-error50);
  --ld-auth-text-hyperlink: var(--ld-ref-palette-primary50);
  --ld-auth-login-text-hyperlink: var(--ld-auth-text-hyperlink);
  --ld-auth-login-text-error: var(--ld-auth-text-color-error);

  // TextField
  --lincd-text-field-helper-text-color: var(--ld-ref-palette-primary50);
  --lincd-input-inputfield-border-width: 1px;

  // footer menu
  --footer-height: 90px;
}
