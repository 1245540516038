@import '../scss/basic.scss';
@import '../scss/helper';

.SearchBarContainer {
  margin: pxToRem(20px);

  &::placeholder {
    color: var(--lincd-color-text-black);
  }
}

.SearchBarInputContainer {
  position: relative; /* Add relative positioning to the container */
  border-radius: pxToRem(25px);
  padding: 15px 0;

  input {
    border: 1px solid #c6c6c6;
    border-radius: pxToRem(30px);
    padding: pxToRem(20px);
    width: 100%;
    text-indent: 50px;
    background: #f9f9f9;
  }

  input::placeholder {
    text-indent: 50px;
  }

  /* Adding the search icon to the container */
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: pxToRem(20px);
    transform: translateY(-50%);
    width: pxToRem(30px);
    height: pxToRem(30px);
    background: url('/images/Search.png') no-repeat center center;
    background-size: contain;
  }

  &::before {
    content: "";
    position: absolute;
    position: absolute;
    top: 50%;
    left: 0;
    border: 1px solid #c6c6c6;
    border-radius: 100px 0 0 100px;
    transform: translateY(-50%);
    width: pxToRem(60px);
    height: pxToRem(59px);
    background-color: var(--lincd-color-background);
  }
}

.SearchBarInput {
  display: block;
}
