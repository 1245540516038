.Practicioners {
  display: block;
}

@import '../scss/helper.scss';
@import '../scss/basic.scss';

.PracticionerList {
  position: relative;
  top: -40px;
  padding: 10px 20px;
  border-radius: 25px 25px 0 0;
  margin: 0 auto;
  z-index: 5;
  background-color: var(--lincd-color-background);
  max-width: 1200px; /* Set a maximum width for the container */
}

.PracticionerContainer {
  list-style: none;
  margin-top: 0;
  padding-left: 0;
  display: grid; /* Use grid layout */
  grid-template-rows: repeat(2, auto); /* Two rows */
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  gap: 20px; /* Add gap between grid items */
}

.PracticionerPerson {
  background-color: var(--lincd-color-background);
  padding: 15px;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  align-items: center;
  box-shadow: 0 10px 28px 0px #cccccc36;
  box-sizing: border-box;
  border: 1px solid #e8e8e8d1;
  border-radius: 15px;
  overflow: hidden;
}

/* Remove flex-related styles from PracticionerInnerContentList and PracticionerContact */

.imageContainer {
  width: 100%;
  border: 1px solid #eee;
  border-radius: pxToRem(20px);
  overflow: hidden;
  height: 120px;
  width: 120px;
}

/* Remove .flexRow class */

.profesi {
  color: var(--lincd-color-primary);
  margin-bottom: 20px;
  margin-top: 10px;
  text-align: center;
  font-family: var(--lincd-font-family-regular);
}

.seeAll {
  font-size: pxToRem(16px);
  color: #ccc;
}

.imgDoctor {
  background-size: contain;
  width: 100%;
  height: 100%;
  border-radius: 100px;
}

.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: -10px;
}

.profesiName {
  margin-top: 0;
  margin-bottom: -5px;
  text-align: center;
}

.practicionerContact {
  display: flex;
  flex-direction: column;
}

.profesiDesc {
  line-height: 24px;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to display */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 5px;
  color: var(--lincd-primary-gray-500);
}

.practionerLocation,
.practionerPhone {
  margin-left: 5px;
  color: var(--lincd-color-text-black);
  font-size: 15px; /* Use fixed font size */
}

.imgIcon {
  height: 32px; /* Use fixed height */
  width: 32px; /* Use fixed width */
}

.PracticionerContentList {
  flex: 2;
}

.PracticionerInnerContentList {
  flex-direction: column;
  padding-right: 0;
  margin-top: 20px;
}

.headingPracticioner {
  margin-top: 10px;
  margin-bottom: 10px;
}

.PracticionerContact {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-left: -10px;
}

.flexSide {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 5px 10px auto;
  margin-top: 15px;
  align-self: flex-start;
  margin-bottom: 10px;
}
