.layout {
  min-height: 100vh;
}

.wrapper {
  padding: 1.5rem;
}

.iframe {
  width: 100%;
  height: 70vh;
}
