.info {
  margin-top: 2rem;
  text-align: center;
  color: #141414;
  font-size: var(--lincd-font-size-small);
  line-height: var(--lincd-line-height-normal);
  color: var(--ld-ref-palette-neutral-variant40);

  p {
    margin: 0 0 0.15rem;
  }
}

.contact {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    transform: scale(0.6);
  }
}
