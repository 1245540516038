.inputContainer {
  //defining the components variables
  --lincd-input-inputfield-border-radius: var(--ld-app-box-border-radius);
  --lincd-input-inputfield-border-color: var(--ld-app-box-border-color);
  --lincd-input-inputfield-border-width: var(--ld-app-box-border-width);
  --lincd-input-inputfield-background-color: var(--ld-app-color-on-primary);
  --lincd-input-inputfield-text-font: var(--ld-app-body-text-family);
  --lincd-input-inputfield-text-size: var(--ld-ref-font-size-m);

  display: flex;
  flex-direction: column;
  position: relative;

  .spinner {
    position: absolute;
    width: 35px;
    margin-top: 7px;
    right: 3px;
  }
  .labelContainer {
    display: flex;
    gap: 3px;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;
  }
}

.input {
  font-family: var(--lincd-input-inputfield-text-font);
  font-size: var(--lincd-input-inputfield-text-size);
  font-weight: 400;
  line-height: 1.5;
  border-radius: var(--lincd-input-inputfield-border-radius);
  color: var(--lincd-color-primary);
  background-color: var(--lincd-input-inputfield-background-color);
  border-width: var(--lincd-input-inputfield-border-width);
  border-color: var(--lincd-input-inputfield-border-color);
  padding: var(--ld-ref-spacing-m);

  width: 100%;

  // to remove the up down arrows & calendar default icon on the input with type number OPENING
  // Chrome, Safari, Edge, Opera
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button,
  input[type='date']::-webkit-inner-spin-button,
  input[type='date']::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }

  input[type='text'],
  input[type='number'],
  input[type='date'] {
    -moz-appearance: textfield;
  }

  input:autofill {
    color: var(--ld-app-color-primary);
  }

  input:-webkit-autofill {
    color: var(--ld-app-color-primary);
  }

  > img {
    margin: 0 0 0 12px !important;
  }
}

.helperText {
  font-size: 0.85rem;
  color: var(--ld-app-color-error);
  margin: 0.5rem;
}
