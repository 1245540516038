/* Design System Variables Documentation */
/* -- deni@semantu.com --
/* This SCSS document contains variables for a basic design system for lincd. */
/* These variables define colors, typography, spacing, and more. */

/* Colors */
:root {
  /* Brand Colors */
  --lincd-color-primary: #1372ee;
  --lincd-color-secondary: #e8f1ff;
  --lincd-color-tertiary: #0f6dc5;

  /* Color Text */
  --lincd-color-text-black: #000000;
  --lincd-color-text-white: #ffffff;
  --lincd-color-text-muted: #eee;
  --lincd-color-background: #ffffff;
  --lincd-color-background-transparent: rgba(255, 255, 255, 0.8);
  --lincd-color-link: #1372ee;
  --lincd-color-error: #ff0000;
  --lincd-color-success: #00cc00;

  /* Gradient Colors */
  --lincd-gradient-primary-brand: #3a99f1;
  --lincd-gradient-secondary-brand: #0053d0;
  --lincd-gradient-primary-brand-white: #ffffff;
  --lincd-gradient-secondary-brand-white: #d9d9d9;

  /* Primary Blue */
  --lincd-primary-blue-100: #e6f3ff;
  --lincd-primary-blue-200: #e8f1ff;
  --lincd-primary-blue-300: #99c2ff;
  --lincd-primary-blue-400: #73a9ff;
  --lincd-primary-blue-500: #009df9;
  --lincd-primary-blue-600: #2678ff;
  --lincd-primary-blue-700: #0060ff;
  --lincd-primary-blue-800: #1372ee;
  --lincd-primary-blue-900: #0054cc;

  /* Tertiary Orange */
  --lincd-color-orange-100: #ffe0b2;
  --lincd-color-orange-200: #ffcc80;
  --lincd-color-orange-300: #ffb74d;
  --lincd-color-orange-400: #ffa726;
  --lincd-color-orange-500: #ff9800; /* Base color */
  --lincd-color-orange-600: #fb8c00;
  --lincd-color-orange-700: #f57c00;
  --lincd-color-orange-800: #ef6c00;
  --lincd-color-orange-900: #e65100;

  /* Tertiary Green */
  --lincd-color-green-100: #c8e6c9;
  --lincd-color-green-200: #a5d6a7;
  --lincd-color-green-300: #81c784;
  --lincd-color-green-400: #66bb6a;
  --lincd-color-green-500: #4caf50; /* Base color */
  --lincd-color-green-600: #43a047;
  --lincd-color-green-700: #388e3c;
  --lincd-color-green-800: #2e7d32;
  --lincd-color-green-900: #1b5e20;

  /* Primary Black */
  --lincd-primary-black-100: #232526;
  --lincd-primary-black-200: #333536;
  --lincd-primary-black-300: #434647;
  --lincd-primary-black-400: #535758;
  --lincd-primary-black-500: #636869;

  /* Primary Gray */
  --lincd-primary-gray-100: #303846;
  --lincd-primary-gray-200: #404956;
  --lincd-primary-gray-300: #505a66;
  --lincd-primary-gray-400: #606a76;
  --lincd-primary-gray-500: #707a86;
  --lincd-primary-gray-1000: #e6e6e6;

  /* Color Shadow */
  --lincd-color-text-shadow: rgba(0, 0, 0, 0.3);
  --lincd-color-box-shadow: 0 5px 6px rgba(0, 0, 0, 0.2);
  --lincd-color-box-shadow-inset: 0 6px 10px rgba(0, 0, 0, 0.2) inset;
}

/* Typography */
:root {
  /* Font Families */
  --lincd-font-family-primary: 'Roboto', sans-serif;
  --lincd-font-family-secondary: 'Arial', sans-serif;
  --lincd-font-family-monospace: 'Courier New', monospace;

  /* Font Weights */
  --lincd-font-weight-light: 300;
  --lincd-font-weight-regular: 400;
  --lincd-font-weight-bold: 700;

  /* Font Sizes */
  --lincd-font-size-small: 14px;
  --lincd-font-size-medium: 16px;
  --lincd-font-size-regular: 18px;
  --lincd-font-size-medium-extra: 20px;
  --lincd-font-size-large: 24px;
  --lincd-font-size-large-extra: 26px;
  --lincd-font-size-heading1: 2rem;
  --lincd-font-size-heading2: 1.75rem;
  --lincd-font-size-heading3: 1.5rem;
  --lincd-font-size-heading4: 1.25rem;
  --lincd-font-size-heading5: 1.125rem;

  /* Line Heights */
  --lincd-line-height-normal: 1.5;
  --lincd-line-height-heading: 1.2;

  /* Letter Spacing */
  --lincd-letter-spacing-normal: 0.05em;
  --lincd-letter-spacing-heading: 0.1em;

  /* Text Align */
  --lincd-text-align-left: left;
  --lincd-text-align-center: center;
  --lincd-text-align-right: right;
  --lincd-text-align-justify: justify;

  /* Text Overflow */
  --lincd-text-overflow-ellipsis: . . .;
}

/* Spacing and Layout */
:root {
  /* Spacing */
  --lincd-spacing-unit: 8px;
  --lincd-spacing-small: var(--lincd-spacing-unit);
  --lincd-spacing-medium: calc(2 * var(--lincd-spacing-unit));
  --lincd-spacing-large: calc(3 * var(--lincd-spacing-unit));
  --lincd-spacing-extra-large: calc(4 * var(--lincd-spacing-unit));
  --lincd-spacing-horizontal: var(--lincd-spacing-unit);
  --lincd-spacing-vertical: var(--lincd-spacing-unit);

  /* Margins */
  --lincd-margin-base: var(--lincd-spacing-unit);
  --lincd-margin-small: var(--lincd-spacing-small);
  --lincd-margin-medium: var(--lincd-spacing-medium);
  --lincd-margin-large: var(--lincd-spacing-large);
  --lincd-margin-extra-large: var(--lincd-spacing-extra-large);
  --lincd-margin-top: 0;
  --lincd-margin-right: var(--lincd-spacing-unit);
  --lincd-margin-bottom: var(--lincd-spacing-unit);
  --lincd-margin-left: var(--lincd-spacing-unit);

  /* Paddings */
  --lincd-padding-base: var(--lincd-spacing-unit);
  --lincd-padding-small: var(--lincd-spacing-small);
  --lincd-padding-medium: var(--lincd-spacing-medium);
  --lincd-padding-large: var(--lincd-spacing-large);
  --lincd-padding-extra-large: var(--lincd-spacing-extra-large);
  --lincd-padding-top: 0;
  --lincd-padding-right: var(--lincd-spacing-unit);
  --lincd-padding-bottom: var(--lincd-spacing-unit);
  --lincd-padding-left: var(--lincd-spacing-unit);

  /* Layout */
  --lincd-container-max-width: 1200px;
  --lincd-container-min-width: 100vw;
  --lincd-header-height: 64px;
  --lincd-footer-height: 48px;
  --lincd-sidebar-width: 240px;
  --lincd-content-spacing: var(--lincd-spacing-medium);
  --lincd-section-spacing: var(--lincd-spacing-large);
  --lincd-grid-gap: var(--lincd-spacing-medium);

  /* Z-Index */
  --lincd-z-index-header: 100;
  --lincd-z-index-modal: 200;
  --lincd-z-index-dropdown: 150;

  /* Border Radius */
  --lincd-border-radius-small: 4px;
  --lincd-border-radius-medium: 8px;
  --lincd-border-radius-large: 12px;

  /* Width and Height */
  --lincd-width-full: 100%;
  --lincd-height-full: 100%;

  /* Flexbox and Grid */
  --lincd-flex-justify-center: center;
  --lincd-flex-align-center: center;
  --lincd-grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

/* Components */
/* Button Styles */
:root {
  /* Brand Spesific */
  --lincd-button-bg-primary: var(--lincd-color-primary);
  --lincd-button-text-color: var(--lincd-color-background);
  --lincd-button-hover-bg: var(--lincd-primary-blue-500);
  --lincd-button-hover-text-color: var(--lincd-color-text);
  --lincd-button-placeholder-primary: var(--lincd-color-background);
  --lincd-button-placeholder-secondary: var(--lincd-primary-blue-200);
  --lincd-button-gradient-start: var(--lincd-color-primary);
  --lincd-button-gradient-end: var(--lincd-color-secondary);
  --lincd-button-border-width: 1px;
  --lincd-button-border-color: var(--lincd-color-tertiary);
  --lincd-button-border-radius: 4px;
  --lincd-button-padding-horizontal: 16px;
  --lincd-button-padding-vertical: 10px;
  --lincd-button-box-shadow: var(--lincd-color-box-shadow);

  /* Clicked Button Styles */
  --lincd-button-clicked-bg: var(--lincd-color-primary);
  --lincd-button-clicked-text-color: var(--lincd-color-background);
  --lincd-button-clicked-border-color: var(--lincd-color-primary);
  --lincd-button-clicked-border-radius: 4px;

  /* Primary Button Colors */
  --lincd-primary-button-background: #3498db;
  --lincd-primary-button-color: #ffffff;
  --lincd-primary-button-border: 1px solid #3498db;

  /* Primary Button Hover State */
  --lincd-primary-button-hover-background: #2980b9;
  --lincd-primary-button-hover-color: #ffffff;
  --lincd-primary-button-hover-border: 1px solid #2980b9;

  /* Primary Button Focus State */
  --lincd-primary-button-focus-background: #e74c3c;
  --lincd-primary-button-focus-color: #ffffff;
  --lincd-primary-button-focus-border: 1px solid #e74c3c;
  --lincd-primary-button-focus-outline: 2px solid rgba(231, 76, 60, 0.6);

  /* Primary Button Active State */
  --lincd-primary-button-active-background: #e67e22;
  --lincd-primary-button-active-color: #ffffff;
  --lincd-primary-button-active-border: 1px solid #e67e22;

  /* Primary Button Disabled State */
  --lincd-primary-button-disabled-background: #d1d1d1;
  --lincd-primary-button-disabled-color: #ffffff;
  --lincd-primary-button-disabled-border: 1px solid #d1d1d1;
  --lincd-primary-button-disabled-opacity: 0.6;

  /* Secondary Button Colors */
  --lincd-secondary-button-background: #ffffff;
  --lincd-secondary-button-color: #3498db;
  --lincd-secondary-button-border: 1px solid #3498db;

  /* Secondary Button Hover State */
  --lincd-secondary-button-hover-background: #f2f2f2;
  --lincd-secondary-button-hover-color: #2980b9;
  --lincd-secondary-button-hover-border: 1px solid #2980b9;

  /* Secondary Button Focus State */
  --lincd-secondary-button-focus-background: #e74c3c;
  --lincd-secondary-button-focus-color: #ffffff;
  --lincd-secondary-button-focus-border: 1px solid #e74c3c;
  --lincd-secondary-button-focus-outline: 2px solid rgba(231, 76, 60, 0.6);

  /* Secondary Button Active State */
  --lincd-secondary-button-active-background: #e67e22;
  --lincd-secondary-button-active-color: #ffffff;
  --lincd-secondary-button-active-border: 1px solid #e67e22;

  /* Secondary Button Disabled State */
  --lincd-secondary-button-disabled-background: #d1d1d1;
  --lincd-secondary-button-disabled-color: #3498db;
  --lincd-secondary-button-disabled-border: 1px solid #d1d1d1;
  --lincd-secondary-button-disabled-opacity: 0.6;

  /* Outline Button Colors */
  --lincd-outline-button-background: transparent;
  --lincd-outline-button-color: #3498db;
  --lincd-outline-button-border: 1px solid #3498db;

  /* Outline Button Hover State */
  --lincd-outline-button-hover-background: #f2f2f2;
  --lincd-outline-button-hover-color: #2980b9;
  --lincd-outline-button-hover-border: 1px solid #2980b9;

  /* Outline Button Focus State */
  --lincd-outline-button-focus-background: #e74c3c;
  --lincd-outline-button-focus-color: #ffffff;
  --lincd-outline-button-focus-border: 1px solid #e74c3c;
  --lincd-outline-button-focus-outline: 2px solid rgba(231, 76, 60, 0.6);

  /* Outline Button Active State */
  --lincd-outline-button-active-background: #e67e22;
  --lincd-outline-button-active-color: #ffffff;
  --lincd-outline-button-active-border: 1px solid #e67e22;

  /* Outline Button Disabled State */
  --lincd-outline-button-disabled-background: transparent;
  --lincd-outline-button-disabled-color: #d1d1d1;
  --lincd-outline-button-disabled-border: 1px solid #d1d1d1;
  --lincd-outline-button-disabled-opacity: 0.6;
}

/* Input Variables */
:root {
  --lincd-input-background-color: #f2f2f2;
  --lincd-input-border: 1px solid #ccc;
  --lincd-input-padding: 10px;
  --lincd-input-font-size: 14px;
  --lincd-input-placeholder-color: #999;

  --lincd-input-textfield-border-radius: 30px;
}

/* Checkbox Variables */
:root {
  --lincd-checkbox-size: 16px;
  --lincd-checkbox-border: 1px solid #ccc;
  --lincd-checkbox-background-color: #fff;
  --lincd-checkbox-checked-background-color: #1372ee;
  --lincd-checkbox-checked-border-color: #1372ee;
}

/* Radio Button Variables */
:root {
  --lincd-radio-size: 16px;
  --lincd-radio-border: 1px solid #ccc;
  --lincd-radio-dot-background-color: #1372ee;
}

/* Select Variables */
:root {
  --lincd-select-background-color: #f2f2f2;
  --lincd-select-border: 1px solid #ccc;
  --lincd-select-padding: 10px;
  --lincd-select-font-size: 14px;
  --lincd-select-arrow-color: #333;
}

/* Textarea Variables */
:root {
  --lincd-textarea-background-color: #f2f2f2;
  --lincd-textarea-border: 1px solid #ccc;
  --lincd-textarea-padding: 10px;
  --lincd-textarea-font-size: 14px;
}

/* Tooltip Variables */
:root {
  --lincd-tooltip-background-color: #333;
  --lincd-tooltip-text-color: #fff;
  --lincd-tooltip-arrow-size: 6px;
}

/* Modal Variables */
:root {
  --lincd-modal-overlay-background-color: rgba(0, 0, 0, 0.5);
  --lincd-modal-background-color: #fff;
  --lincd-modal-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Dropdown Variables */
:root {
  --lincd-dropdown-background-color: #fff;
  --lincd-dropdown-border: 1px solid #ccc;
  --lincd-dropdown-item-background-color: #fff;
  --lincd-dropdown-item-hover-background-color: #f2f2f2;
}

/* Progress Bar Variables */
:root {
  --lincd-progress-bar-background-color: #f2f2f2;
  --lincd-progress-bar-fill-color: #1372ee;
}

/* Toggle Switch Variables */
:root {
  --lincd-toggle-switch-width: 40px;
  --lincd-toggle-switch-height: 20px;
  --lincd-toggle-switch-background-color: #ccc;
  --lincd-toggle-switch-button-background-color: #1372ee;
  --lincd-toggle-switch-button-background-color-active: #efb8c8;
}

/* Usage Examples */
.example {
  background-color: var(--lincd-color-primary);
  font-family: var(--lincd-font-family-primary);
  font-size: var(--lincd-font-size-heading1);
  margin: var(--lincd-spacing-unit);
  max-width: var(--lincd-container-max-width);

  .button {
    border-radius: var(--lincd-border-radius-small);
    box-shadow: var(--lincd-button-box-shadow);
  }
}
