:root {
  --lincd-modal-z-index: 1300;

  // box content
  --lincd-modal-content-box-shadow: 0px 2px 24px #383838;
  --lincd-modal-content-background-color: #fff;
  --lincd-modal-content-border-radius: 12px;

  // backdrop
  --lincd-modal-backdrop-background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  position: fixed;
  z-index: var(--lincd-modal-z-index);
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.backdrop {
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: var(--lincd-modal-backdrop-background-color);
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
}

.content {
  box-sizing: border-box;
  //min-width: 450px;
  border-radius: var(--lincd-modal-content-border-radius);
  //padding: 1.5rem 2rem;
  background-color: var(--lincd-modal-content-background-color);
  box-shadow: var(--lincd-modal-content-box-shadow);
  max-height: 100vh;
  overflow-y: auto;
  @media (max-width: 768px) {
    max-width: calc(100% - 48px);
    min-width: auto;
  }
  @media (max-width: 512px) {
    max-width: calc(100% - 24px);
  }
}
