.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;

  .innerSpin {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  p {
    text-align: center;
    width: 250px;
    line-height: 28px;
  }

  .fadeIn {
    opacity: 0; /* Start with 0 opacity */
    animation: fadeInOut 3s linear infinite; /* Use the fadeInOut animation */
  }

  /* Define the fadeInOut animation */
  @keyframes fadeInOut {
    0%, 100% {
      opacity: 0; /* Start and end with 0 opacity */
    }
    50% {
      opacity: 1; /* Middle of the animation with full opacity */
    }
  }

  .imgSpinner {
    height: 200px;

  }
}


// @keyframes spin {
//   0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }
  