.spinner.active {
  border: 3px solid transparent;
  border-top: 3px solid #474545;
  border-right: 3px solid #474545;
  border-bottom: 3px solid #474545;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
