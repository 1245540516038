@import '../scss/helper';
@import '../scss/basic.scss';

.Container {
  background: var(--lincd-color-background);
  height: 100%;
}

.innerContainer {
  padding: pxToRem(20px);
  align-items: center;
  display: flex;
  position: relative;
  top: -20px;
  flex-direction: column;
}

.businessType {
  margin: 0;
  font-size: var(--lincd-font-size-regular);
  margin-bottom: pxToRem(20px);
}

h3 {
  margin-bottom: pxToRem(5px);
}

.nfcScanner {
  position: relative;
  top: -100px;
  height: calc(70vh - 11vh);
}

.textNFC {
  position: relative;
  top: -200px;
}

.bumpPhone {
  width: 100%;
  height: calc(100vh - 20vh);
}