.privacyPolicyContainer {
  width: 90%;
  margin: auto;
  font-family: 'Roboto';
  padding-top: 1rem;
  margin: auto;
  line-height: 1.5;

  ol {
    padding-left: 20px;
  }
}

.subtitle {
  text-align: center;
}

.privacyPolicyContainer p,
.privacyPolicyContainer li {
  margin-bottom: 10px;
  //   margin: 20px 0 20px 0;
}

.privacyPolicyContainer h1 {
  margin-bottom: 10px;
}

.privacyPolicyContainer h2 {
  margin-bottom: 10px;
}

.listBullet {
  padding-left: 25px;
}
