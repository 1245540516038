@import '../scss/helper';
@import '../scss/basic.scss';

.profileContainer {
  padding-top: 0;
  position: relative;
  // min-height: 100vh;
}

.backgroundImage {
  background-image: url('/images/headerBg.jpg');
  background-size: cover;
  height: 160px;
}

.profileBadge {
  position: absolute;
  top: -142px;
  margin: 0 auto;
  left: 0;
  right: 0;

  p {
    color: #aaaaaa;
    font-size: 0.8rem;
    margin: 5px;
  }
}

.profilePic {
  width: 140px;
  border-radius: 10px;
  height: 140px;
  //top: 80px;
  margin: 0 auto;
  //left: 0;
  //right: 0;
  //bottom: 0;
  //position: absolute;
  object-fit: cover;
  object-position: center;
}

.connectionHeader {
  margin-top: 10px !important;
}

.bioProfile {
  padding: 25px;
  text-align: center;
  top: 50px;
  position: relative;
  max-width: 600px;
  margin: 0 auto;
}

.hashprofile {
  color: var(--lincd-primary-gray-500);
  margin-top: 40px;
  margin-bottom: 10px;
}

.nameProfileBlock {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nameProfile {
  margin: 0;
}

.verifiedIcon {
  width: 26px;
  height: 26px;
  margin-left: 5px;
}

.bioBadge {
  //display: flex;
  //justify-content: space-around;
  width: 250px;
  //top: -25px;
  position: relative;
  margin: 0 auto 1.5rem;
  align-items: center;

  p {
    color: var(--lincd-primary-gray-600);
  }
}

.bioDesc {
  display: block;

  > p {
    line-height: 30px;
    padding: 0 1.5rem;
  }
}

.headingBio {
  margin-left: 20px;
}

.editImg {
  height: 20px;
  width: 20px;
  margin-left: 10px;

  &:hover {
    cursor: pointer;
  }
}

.bioInformation {
  list-style: none;
  margin-left: 0;
  padding: 10px 0px;

  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .labelBio {
      color: var(--lincd-primary-gray-500);
      display: flex;
      flex-direction: row;
      align-items: center;

      img {
        height: pxToRem(24px);
        width: pxToRem(24px);
        margin-right: 10px;
      }
    }

    .labelInput {
      display: flex;
      color: var(--lincd-color-text-black);
      align-items: center;

      img {
        height: pxToRem(24px) !important;
        margin-right: 10px;
        width: pxToRem(24px) !important;
      }
    }
  }
}

// tabs
.tabContainer {
  display: flex;

  & > button[tabindex='0'] {
    background-color: var(--lincd-color-primary);
    color: var(--lincd-color-secondary);
    transition: all 0.3s;
  }
}

.tab {
  color: var(--lincd-color-primary);
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: var(--lincd-color-secondary);
  width: 100%;
  line-height: 1.5;
  padding: 8px 12px;
  margin: 6px;
  border: none;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}

.buttonSignOut {
  margin: 0 0 1rem!important;
}

.layout {
  min-height: 100vh;
  padding-bottom: 100px;
}
