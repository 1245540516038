@import '../scss/helper.scss';
@import '../scss/basic.scss';

.PracticionerCard {
  display: block;
}

.PracticionerPerson {
  background-color: var(--lincd-color-background);
  padding: 15px;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  align-items: center;
  box-shadow: 0 10px 28px 0px #cccccc36;
  box-sizing: border-box;
  border: 1px solid #e8e8e8d1;
  border-radius: 15px;
  overflow: hidden;
  cursor: pointer;
}

/* Remove flex-related styles from PracticionerInnerContentList and PracticionerContact */

.imageContainer {
  width: 100%;
  border: 1px solid #eee;
  border-radius: pxToRem(20px);
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }
}

/* Remove .flexRow class */

.profesi {
  color: var(--lincd-color-primary);
  margin-bottom: 20px;
  margin-top: 10px;
  text-align: center;
  font-family: var(--lincd-font-family-regular);
}

.imgDoctor {
  background-size: contain;
  width: 100%;
  height: 100%;
  //border-radius: 100px;
}

.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: -10px;
}

.profesiNameWrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profesiName {
  margin-top: 0;
  margin-bottom: -5px;
  // text-align: center;
}

.practicionerContact {
  display: flex;
  flex-direction: column;
}

.profesiDesc {
  line-height: 24px;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to display */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 5px;
  color: var(--lincd-primary-gray-500);
}

.practionerLocation,
.practionerPhone {
  margin-left: 5px;
  color: var(--lincd-color-text-black);
  font-size: 15px; /* Use fixed font size */
}

.imgIcon {
  height: 32px; /* Use fixed height */
  width: 32px; /* Use fixed width */
}

.PracticionerContentList {
  flex: 2;
}

.PracticionerInnerContentList {
  flex-direction: column;
  padding-right: 0;
  margin-top: 20px;
}

.PracticionerContact {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-left: -10px;
}

.verifiedIcon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  position: relative;
  top: 2px;
}

.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
