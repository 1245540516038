.container {
  width: 100%;

  .otpHeadline {
    padding-bottom: 15px;
  }

  .innerContainer {
    width: 100%; /* Set the width to 100% */
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;

      .MuiInput-root {
        text-align: center;
      }

      input,
      #textInput {
        color: #222;
        font-size: 20px;
        font-weight: 600;
        width: 100%; /* Set the width to 100% */
        padding: 15px;
        border-radius: 10px;
        text-align: center;
      }
    }
  }

  .verifyButton {
    width: 100%;
    height: 40px;
    border-radius: 10px;
  }
}
